const data = [
    "24-hours",
    "4k",
    "a-b",
    "account-box",
    "account-circle",
    "account-pin-box",
    "account-pin-circle",
    "add-box",
    "add-circle",
    "add",
    "admin",
    "advertisement",
    "airplay",
    "alarm",
    "alarm-warning",
    "album",
    "alert",
    "aliens",
    "align-bottom",
    "align-center",
    "align-justify",
    "align-left",
    "align-right",
    "align-top",
    "align-vertically",
    "alipay",
    "amazon",
    "anchor",
    "ancient-gate",
    "ancient-pavilion",
    "android",
    "angularjs",
    "anticlockwise-2",
    "anticlockwise",
    "app-store",
    "apple",
    "apps-2",
    "apps",
    "archive-drawer",
    "archive",
    "arrow-down-circle",
    "arrow-down",
    "arrow-down-s",
    "arrow-drop-down",
    "arrow-drop-left",
    "arrow-drop-right",
    "arrow-drop-up",
    "arrow-go-back",
    "arrow-go-forward",
    "arrow-left-circle",
    "arrow-left-down",
    "arrow-left",
    "arrow-left-right",
    "arrow-left-s",
    "arrow-left-up",
    "arrow-right-circle",
    "arrow-right-down",
    "arrow-right",
    "arrow-right-s",
    "arrow-right-up",
    "arrow-up-circle",
    "arrow-up-down",
    "arrow-up",
    "arrow-up-s",
    "artboard-2",
    "artboard",
    "article",
    "aspect-ratio",
    "asterisk",
    "at",
    "attachment-2",
    "attachment",
    "auction",
    "award",
    "baidu",
    "ball-pen",
    "bank-card-2",
    "bank-card",
    "bank",
    "bar-chart-2",
    "bar-chart-box",
    "bar-chart",
    "bar-chart-grouped",
    "bar-chart-horizontal",
    "barcode-box",
    "barcode",
    "barricade",
    "base-station",
    "basketball",
    "battery-2-charge",
    "battery-2",
    "battery-charge",
    "battery",
    "battery-low",
    "battery-saver",
    "battery-share",
    "bear-smile",
    "behance",
    "bell",
    "bike",
    "bilibili",
    "bill",
    "billiards",
    "bit-coin",
    "blaze",
    "bluetooth-connect",
    "bluetooth",
    "blur-off",
    "body-scan",
    "bold",
    "book-2",
    "book-3",
    "book",
    "book-mark",
    "book-open",
    "book-read",
    "booklet",
    "bookmark-2",
    "bookmark-3",
    "bookmark",
    "boxing",
    "braces",
    "brackets",
    "briefcase-2",
    "briefcase-3",
    "briefcase-4",
    "briefcase-5",
    "briefcase",
    "bring-forward",
    "bring-to-front",
    "broadcast",
    "brush-2",
    "brush-3",
    "brush-4",
    "brush",
    "bubble-chart",
    "bug-2",
    "bug",
    "building-2",
    "building-3",
    "building-4",
    "building",
    "bus-2",
    "bus",
    "bus-wifi",
    "cactus",
    "cake-2",
    "cake-3",
    "cake",
    "calculator",
    "calendar-2",
    "calendar-check",
    "calendar-event",
    "calendar",
    "calendar-todo",
    "camera-2",
    "camera-3",
    "camera",
    "camera-lens",
    "camera-off",
    "camera-switch",
    "capsule",
    "car",
    "car-washing",
    "caravan",
    "cast",
    "cellphone",
    "celsius",
    "centos",
    "character-recognition",
    "charging-pile-2",
    "charging-pile",
    "chat-1",
    "chat-2",
    "chat-3",
    "chat-4",
    "chat-check",
    "chat-delete",
    "chat-download",
    "chat-follow-up",
    "chat-forward",
    "chat-heart",
    "chat-history",
    "chat-new",
    "chat-off",
    "chat-poll",
    "chat-private",
    "chat-quote",
    "chat-settings",
    "chat-smile-2",
    "chat-smile-3",
    "chat-smile",
    "chat-upload",
    "chat-voice",
    "check-double",
    "check",
    "checkbox-blank-circle",
    "checkbox-blank",
    "checkbox-circle",
    "checkbox",
    "checkbox-indeterminate",
    "checkbox-multiple-blank",
    "checkbox-multiple",
    "china-railway",
    "chrome",
    "clapperboard",
    "clipboard",
    "clockwise-2",
    "clockwise",
    "close-circle",
    "close",
    "closed-captioning",
    "cloud",
    "cloud-off",
    "cloud-windy",
    "cloudy-2",
    "cloudy",
    "code-box",
    "code",
    "code-s",
    "code-s-slash",
    "code-view",
    "codepen",
    "coin",
    "coins",
    "collage",
    "command",
    "community",
    "compass-2",
    "compass-3",
    "compass-4",
    "compass-discover",
    "compass",
    "compasses-2",
    "compasses",
    "computer",
    "contacts-book-2",
    "contacts-book",
    "contacts-book-upload",
    "contacts",
    "contrast-2",
    "contrast-drop-2",
    "contrast-drop",
    "contrast",
    "copper-coin",
    "copper-diamond",
    "copyleft",
    "copyright",
    "coreos",
    "coupon-2",
    "coupon-3",
    "coupon-4",
    "coupon-5",
    "coupon",
    "cpu",
    "creative-commons-by",
    "creative-commons",
    "creative-commons-nc",
    "creative-commons-nd",
    "creative-commons-sa",
    "creative-commons-zero",
    "criminal",
    "crop-2",
    "crop",
    "css3",
    "cup",
    "currency",
    "cursor",
    "customer-service-2",
    "customer-service",
    "dashboard-2",
    "dashboard-3",
    "dashboard",
    "database-2",
    "database",
    "delete-back-2",
    "delete-back",
    "delete-bin-2",
    "delete-bin-3",
    "delete-bin-4",
    "delete-bin-5",
    "delete-bin-6",
    "delete-bin-7",
    "delete-bin",
    "delete-column",
    "delete-row",
    "device",
    "device-recover",
    "dingding",
    "direction",
    "disc",
    "discord",
    "discuss",
    "dislike",
    "disqus",
    "divide",
    "donut-chart",
    "door-closed",
    "door",
    "door-lock-box",
    "door-lock",
    "door-open",
    "dossier",
    "douban",
    "double-quotes-l",
    "double-quotes-r",
    "download-2",
    "download-cloud-2",
    "download-cloud",
    "download",
    "draft",
    "drag-drop",
    "drag-move-2",
    "drag-move",
    "dribbble",
    "drive",
    "drizzle",
    "drop",
    "dropbox",
    "dual-sim-1",
    "dual-sim-2",
    "dv",
    "dvd",
    "e-bike-2",
    "e-bike",
    "earth",
    "earthquake",
    "edge",
    "edit-2",
    "edit-box",
    "edit-circle",
    "edit",
    "eject",
    "emotion-2",
    "emotion",
    "emotion-happy",
    "emotion-laugh",
    "emotion-normal",
    "emotion-sad",
    "emotion-unhappy",
    "empathize",
    "emphasis-cn",
    "emphasis",
    "english-input",
    "equalizer",
    "eraser",
    "error-warning",
    "evernote",
    "exchange-box",
    "exchange-cny",
    "exchange-dollar",
    "exchange",
    "exchange-funds",
    "external-link",
    "eye-2",
    "eye-close",
    "eye",
    "eye-off",
    "facebook-box",
    "facebook-circle",
    "facebook",
    "fahrenheit",
    "feedback",
    "file-2",
    "file-3",
    "file-4",
    "file-add",
    "file-chart-2",
    "file-chart",
    "file-cloud",
    "file-code",
    "file-copy-2",
    "file-copy",
    "file-damage",
    "file-download",
    "file-edit",
    "file-excel-2",
    "file-excel",
    "file",
    "file-forbid",
    "file-gif",
    "file-history",
    "file-hwp",
    "file-info",
    "file-list-2",
    "file-list-3",
    "file-list",
    "file-lock",
    "file-mark",
    "file-music",
    "file-paper-2",
    "file-paper",
    "file-pdf",
    "file-ppt-2",
    "file-ppt",
    "file-reduce",
    "file-search",
    "file-settings",
    "file-shield-2",
    "file-shield",
    "file-shred",
    "file-text",
    "file-transfer",
    "file-unknow",
    "file-upload",
    "file-user",
    "file-warning",
    "file-word-2",
    "file-word",
    "file-zip",
    "film",
    "filter-2",
    "filter-3",
    "filter",
    "filter-off",
    "find-replace",
    "finder",
    "fingerprint-2",
    "fingerprint",
    "fire",
    "firefox",
    "first-aid-kit",
    "flag-2",
    "flag",
    "flashlight",
    "flask",
    "flight-land",
    "flight-takeoff",
    "flood",
    "flow-chart",
    "flutter",
    "focus-2",
    "focus-3",
    "focus",
    "foggy",
    "folder-2",
    "folder-3",
    "folder-4",
    "folder-5",
    "folder-add",
    "folder-chart-2",
    "folder-chart",
    "folder-download",
    "folder",
    "folder-forbid",
    "folder-history",
    "folder-info",
    "folder-keyhole",
    "folder-lock",
    "folder-music",
    "folder-open",
    "folder-received",
    "folder-reduce",
    "folder-settings",
    "folder-shared",
    "folder-shield-2",
    "folder-shield",
    "folder-transfer",
    "folder-unknow",
    "folder-upload",
    "folder-user",
    "folder-warning",
    "folder-zip",
    "folders",
    "font-color",
    "font-size-2",
    "font-size",
    "football",
    "footprint",
    "forbid-2",
    "forbid",
    "format-clear",
    "fridge",
    "fullscreen-exit",
    "fullscreen",
    "function",
    "functions",
    "funds-box",
    "funds",
    "gallery",
    "gallery-upload",
    "game",
    "gamepad",
    "gas-station",
    "gatsby",
    "genderless",
    "ghost-2",
    "ghost",
    "ghost-smile",
    "gift-2",
    "gift",
    "git-branch",
    "git-commit",
    "git-merge",
    "git-pull-request",
    "git-repository-commits",
    "git-repository",
    "git-repository-private",
    "github",
    "gitlab",
    "global",
    "globe",
    "goblet",
    "google",
    "google-play",
    "government",
    "gps",
    "gradienter",
    "grid",
    "group-2",
    "group",
    "guide",
    "h-1",
    "h-2",
    "h-3",
    "h-4",
    "h-5",
    "h-6",
    "hail",
    "hammer",
    "hand-coin",
    "hand-heart",
    "hand-sanitizer",
    "handbag",
    "hard-drive-2",
    "hard-drive",
    "hashtag",
    "haze-2",
    "haze",
    "hd",
    "heading",
    "headphone",
    "health-book",
    "heart-2",
    "heart-3",
    "heart-add",
    "heart",
    "heart-pulse",
    "hearts",
    "heavy-showers",
    "history",
    "home-2",
    "home-3",
    "home-4",
    "home-5",
    "home-6",
    "home-7",
    "home-8",
    "home",
    "home-gear",
    "home-heart",
    "home-smile-2",
    "home-smile",
    "home-wifi",
    "honor-of-kings",
    "honour",
    "hospital",
    "hotel-bed",
    "hotel",
    "hotspot",
    "hq",
    "html5",
    "ie",
    "image-2",
    "image-add",
    "image-edit",
    "image",
    "inbox-archive",
    "inbox",
    "inbox-unarchive",
    "increase-decrease",
    "indent-decrease",
    "indent-increase",
    "indeterminate-circle",
    "information",
    "infrared-thermometer",
    "ink-bottle",
    "input-cursor-move",
    "input-method",
    "insert-column-left",
    "insert-column-right",
    "insert-row-bottom",
    "insert-row-top",
    "instagram",
    "install",
    "invision",
    "italic",
    "kakao-talk",
    "key-2",
    "key",
    "keyboard-box",
    "keyboard",
    "keynote",
    "knife-blood",
    "knife",
    "landscape",
    "layout-2",
    "layout-3",
    "layout-4",
    "layout-5",
    "layout-6",
    "layout-bottom-2",
    "layout-bottom",
    "layout-column",
    "layout",
    "layout-grid",
    "layout-left-2",
    "layout-left",
    "layout-masonry",
    "layout-right-2",
    "layout-right",
    "layout-row",
    "layout-top-2",
    "layout-top",
    "leaf",
    "lifebuoy",
    "lightbulb",
    "lightbulb-flash",
    "chart",
    "height",
    "link-m",
    "link-unlink-m",
    "link-unlink",
    "link",
    "linkedin-box",
    "linkedin",
    "links",
    "list-check-2",
    "list-check",
    "list-ordered",
    "list-settings",
    "list-unordered",
    "live",
    "loader-2",
    "loader-3",
    "loader-4",
    "loader-5",
    "loader",
    "lock-2",
    "lock",
    "lock-password",
    "lock-unlock",
    "login-box",
    "login-circle",
    "logout-box",
    "logout-box-r",
    "logout-circle",
    "logout-circle-r",
    "luggage-cart",
    "luggage-deposit",
    "lungs",
    "mac",
    "macbook",
    "magic",
    "mail-add",
    "mail-check",
    "mail-close",
    "mail-download",
    "mail",
    "mail-forbid",
    "mail-lock",
    "mail-open",
    "mail-send",
    "mail-settings",
    "mail-star",
    "mail-unread",
    "mail-volume",
    "map-2",
    "map",
    "map-pin-2",
    "map-pin-3",
    "map-pin-4",
    "map-pin-5",
    "map-pin-add",
    "map-pin",
    "map-pin-range",
    "map-pin-time",
    "map-pin-user",
    "mark-pen",
    "markdown",
    "markup",
    "mastercard",
    "mastodon",
    "medal-2",
    "medal",
    "medicine-bottle",
    "medium",
    "men",
    "mental-health",
    "menu-2",
    "menu-3",
    "menu-4",
    "menu-5",
    "menu-add",
    "menu",
    "menu-fold",
    "menu-unfold",
    "merge-cells-horizontal",
    "merge-cells-vertical",
    "message-2",
    "message-3",
    "message",
    "messenger",
    "meteor",
    "mic-2",
    "mic",
    "mic-off",
    "mickey",
    "microscope",
    "microsoft",
    "mind-map",
    "mini-program",
    "mist",
    "money-cny-box",
    "money-cny-circle",
    "money-dollar-box",
    "money-dollar-circle",
    "money-euro-box",
    "money-euro-circle",
    "money-pound-box",
    "money-pound-circle",
    "moon-clear",
    "moon-cloudy",
    "moon",
    "moon-foggy",
    "more-2",
    "more",
    "motorbike",
    "mouse",
    "movie-2",
    "movie",
    "music-2",
    "music",
    "mv",
    "navigation",
    "netease-cloud-music",
    "netflix",
    "newspaper",
    "node-tree",
    "notification-2",
    "notification-3",
    "notification-4",
    "notification-badge",
    "notification",
    "notification-off",
    "npmjs",
    "number-0",
    "number-1",
    "number-2",
    "number-3",
    "number-4",
    "number-5",
    "number-6",
    "number-7",
    "number-8",
    "number-9",
    "numbers",
    "nurse",
    "oil",
    "omega",
    "open-arm",
    "open-source",
    "opera",
    "order-play",
    "organization-chart",
    "outlet-2",
    "outlet",
    "page-separator",
    "pages",
    "paint-brush",
    "paint",
    "palette",
    "pantone",
    "paragraph",
    "parent",
    "parentheses",
    "parking-box",
    "parking",
    "passport",
    "patreon",
    "pause-circle",
    "pause",
    "pause-mini",
    "paypal",
    "pen-nib",
    "pencil",
    "pencil-ruler-2",
    "pencil-ruler",
    "percent",
    "phone-camera",
    "phone",
    "phone-find",
    "phone-lock",
    "picture-in-picture-2",
    "picture-in-picture-exit",
    "picture-in-picture",
    "pie-chart-2",
    "pie-chart-box",
    "pie-chart",
    "pin-distance",
    "ping-pong",
    "pinterest",
    "pinyin-input",
    "pixelfed",
    "plane",
    "plant",
    "play-circle",
    "play",
    "play-list-2",
    "play-list-add",
    "play-list",
    "play-mini",
    "playstation",
    "plug-2",
    "plug",
    "polaroid-2",
    "polaroid",
    "police-car",
    "price-tag-2",
    "price-tag-3",
    "price-tag",
    "printer-cloud",
    "printer",
    "product-hunt",
    "profile",
    "projector-2",
    "projector",
    "psychotherapy",
    "pulse",
    "pushpin-2",
    "pushpin",
    "qq",
    "qr-code",
    "qr-scan-2",
    "qr-scan",
    "question-answer",
    "question",
    "question-mark",
    "questionnaire",
    "quill-pen",
    "radar",
    "radio-2",
    "radio-button",
    "radio",
    "rainbow",
    "rainy",
    "reactjs",
    "record-circle",
    "record-mail",
    "recycle",
    "red-packet",
    "reddit",
    "refresh",
    "refund-2",
    "refund",
    "registered",
    "remixicon",
    "remote-control-2",
    "remote-control",
    "repeat-2",
    "repeat",
    "repeat-one",
    "reply-all",
    "reply",
    "reserved",
    "rest-time",
    "restart",
    "restaurant-2",
    "restaurant",
    "rewind",
    "rewind-mini",
    "rhythm",
    "riding",
    "road-map",
    "roadster",
    "robot",
    "rocket-2",
    "rocket",
    "rotate-lock",
    "rounded-corner",
    "route",
    "router",
    "rss",
    "ruler-2",
    "ruler",
    "run",
    "safari",
    "safe-2",
    "safe",
    "sailboat",
    "save-2",
    "save-3",
    "save",
    "scales-2",
    "scales-3",
    "scales",
    "scan-2",
    "scan",
    "scissors-2",
    "scissors-cut",
    "scissors",
    "screenshot-2",
    "screenshot",
    "sd-card",
    "sd-card-mini",
    "search-2",
    "search-eye",
    "search",
    "secure-payment",
    "seedling",
    "send-backward",
    "send-plane-2",
    "send-plane",
    "send-to-back",
    "sensor",
    "separator",
    "server",
    "service",
    "settings-2",
    "settings-3",
    "settings-4",
    "settings-5",
    "settings-6",
    "settings",
    "shape-2",
    "shape",
    "share-box",
    "share-circle",
    "share",
    "share-forward-2",
    "share-forward-box",
    "share-forward",
    "shield-check",
    "shield-cross",
    "shield",
    "shield-flash",
    "shield-keyhole",
    "shield-star",
    "shield-user",
    "ship-2",
    "ship",
    "shirt",
    "shopping-bag-2",
    "shopping-bag-3",
    "shopping-bag",
    "shopping-basket-2",
    "shopping-basket",
    "shopping-cart-2",
    "shopping-cart",
    "showers",
    "shuffle",
    "shut-down",
    "side-bar",
    "signal-tower",
    "signal-wifi-1",
    "signal-wifi-2",
    "signal-wifi-3",
    "signal-wifi-error",
    "signal-wifi",
    "signal-wifi-off",
    "sim-card-2",
    "sim-card",
    "single-quotes-l",
    "single-quotes-r",
    "sip",
    "skip-back",
    "skip-back-mini",
    "skip-forward",
    "skip-forward-mini",
    "skull-2",
    "skull",
    "skype",
    "slack",
    "slice",
    "slideshow-2",
    "slideshow-3",
    "slideshow-4",
    "slideshow",
    "smartphone",
    "snapchat",
    "snowy",
    "sort-asc",
    "sort-desc",
    "sound-module",
    "soundcloud",
    "space-ship",
    "space",
    "spam-2",
    "spam-3",
    "spam",
    "speaker-2",
    "speaker-3",
    "speaker",
    "spectrum",
    "speed",
    "speed-mini",
    "split-cells-horizontal",
    "split-cells-vertical",
    "spotify",
    "spy",
    "stack",
    "stack-overflow",
    "stackshare",
    "star",
    "star-half",
    "star-half-s",
    "star-s",
    "star-smile",
    "steam",
    "steering-2",
    "steering",
    "stethoscope",
    "sticky-note-2",
    "sticky-note",
    "stock",
    "stop-circle",
    "stop",
    "stop-mini",
    "store-2",
    "store-3",
    "store",
    "strikethrough-2",
    "strikethrough",
    "subscript-2",
    "subscript",
    "subtract",
    "subway",
    "subway-wifi",
    "suitcase-2",
    "suitcase-3",
    "suitcase",
    "sun-cloudy",
    "sun",
    "sun-foggy",
    "superscript-2",
    "superscript",
    "surgical-mask",
    "surround-sound",
    "survey",
    "swap-box",
    "swap",
    "switch",
    "sword",
    "syringe",
    "t-box",
    "t-shirt-2",
    "t-shirt-air",
    "t-shirt",
    "table-2",
    "table-alt",
    "table",
    "tablet",
    "takeaway",
    "taobao",
    "tape",
    "task",
    "taxi",
    "taxi-wifi",
    "team",
    "telegram",
    "temp-cold",
    "temp-hot",
    "terminal-box",
    "terminal",
    "terminal-window",
    "test-tube",
    "text-direction-l",
    "text-direction-r",
    "text-spacing",
    "text-wrap",
    "text",
    "thermometer",
    "thumb-down",
    "thumb-up",
    "thunderstorms",
    "ticket-2",
    "ticket",
    "time",
    "timer-2",
    "timer",
    "timer-flash",
    "todo",
    "toggle",
    "tools",
    "tornado",
    "trademark",
    "traffic-light",
    "train",
    "train-wifi",
    "translate-2",
    "translate",
    "travesti",
    "treasure-map",
    "trello",
    "trophy",
    "truck",
    "tumblr",
    "tv-2",
    "tv",
    "twitch",
    "twitter",
    "typhoon",
    "u-disk",
    "ubuntu",
    "umbrella",
    "underline",
    "uninstall",
    "unsplash",
    "upload-2",
    "upload-cloud-2",
    "upload-cloud",
    "upload",
    "usb",
    "user-2",
    "user-3",
    "user-4",
    "user-5",
    "user-6",
    "user-add",
    "user",
    "user-follow",
    "user-heart",
    "user-location",
    "user-received-2",
    "user-received",
    "user-search",
    "user-settings",
    "user-shared-2",
    "user-shared",
    "user-smile",
    "user-star",
    "user-unfollow",
    "user-voice",
    "video-add",
    "video-chat",
    "video-download",
    "video",
    "video-upload",
    "vidicon-2",
    "vidicon",
    "vimeo",
    "vip-crown-2",
    "vip-crown",
    "vip-diamond",
    "vip",
    "virus",
    "visa",
    "voice-recognition",
    "voiceprint",
    "volume-down",
    "volume-mute",
    "volume-off-vibrate",
    "volume-up",
    "volume-vibrate",
    "vuejs",
    "walk",
    "wallet-2",
    "wallet-3",
    "wallet",
    "water-flash",
    "webcam",
    "wechat-2",
    "wechat",
    "wechat-pay",
    "weibo",
    "whatsapp",
    "wheelchair",
    "wifi",
    "wifi-off",
    "window-2",
    "window",
    "windows",
    "windy",
    "wireless-charging",
    "women",
    "wubi-input",
    "xbox",
    "xing",
    "youtube",
    "zcool",
    "zhihu",
    "zoom-in",
    "zoom-out",
    "zzz"
]

export default data